.money{
    background-color: whitesmoke;
    
}
.money h2{
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    padding-top: 20px;
    margin:10px;
}
.money{
    font-family: 'Montserrat', sans-serif;
    
}
.money p{
    text-align: center;
    margin: 15px;
    font-family: sans-serif;
}
.price{
    display: flex;
    height: 350px;
    width: 80%;
    margin: auto;
    justify-content: center;
    gap: 20px;
}
.price_1{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    height:100%;
    width: 40%;
    padding: 10px;
}
.price_1 img{
    width: 40px;
    margin: 5px;
}
.button{
    background-color: blue;
    height: 30px;
    width: 160px;
    color: white;
    outline: none;
    cursor: pointer;
    border: none;
    /* margin: 3rem; */
    font-weight: 200;
    align-items:center;
}
.btn{
    background-color:white;
    height: 30px;
    width: 160px;
}
@media(max-width:1017px){
    .money{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .money p{
        text-align: center;
        /* font-size: 20px; */
    }
    .money h2{
        text-align: center;
    }
    .price{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 50%;

    }
}
.flex{
    /* height:200px; */
    width:80%;
    display:flex;
    flex-direction:row;
    margin:auto;
    margin-top: 80px;
    /* background-color: tomato; */
    gap: 3rem
}
.item{
    display:flex;
    flex-direction:column;
    gap: .5rem
    /* align-items: center; */
}
.item h2{
    font-family: 'Montserrat', sans-serif;
}
.item img{
    width:  30px;
    height: auto
}
@media(max-width:1017px){
    .flex{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .item{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .item p{
    text-align: center;
    }
}

.overall{

    width:85%;
    margin: auto; 
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin:auto;
    gap: 4rem;

}
.overall h2{
    font-family: 'Montserrat', sans-serif;
}


.part{
    padding: 50px;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 80%;
    text-align:center;
    justify-content: center;
    /* gap:8rem; */
    align-items: center;
}
.part img{
    width: 30px;
}
.part_1a{
    padding-bottom: 50px;
}
.part_1a h2{
    padding-bottom: 5px;
    margin-top: 2px;
}
.part_1b h2{
    padding-bottom: 5px;
    margin-top: 2px;
}

.part_2{
    
    height: 450px;
    flex-basis: 100%;
    padding-top:50px;
}
.part_2 img{
    height: 100%;
     width: 100%;
}
.part_3{
    
    /* height: 400px; */
    width:100%;
    text-align: center;
}
.part_3a{
    padding-bottom: 50px;
}
.part_3a h2{
    padding-bottom: 5px;
    margin-top: 2px;
}
.part_3b h2{
    padding-bottom: 5px;
    margin-top: 2px;
}
/* .overall img{
        width:  30px;
        height: auto
} */
@media (max-width:1017px){
    .overall{
        display:flex;
        flex-direction: column;
    }
    .part_2{
        display: flex;
        justify-content: center;
    }

    .part_2 img{
        width: 200px;
    }
}
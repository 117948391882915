.footer{
        width: 100%;
        height: 400px;
        background-color: rgb(1, 1, 26);
        display: flex;
        justify-content: center;
        align-items: center;

}
.footer_1{
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
    /* background-color: red; */
    width: 40%;
    /* height: 250px; */
   


}
.footer_1 h3{
    font-family: 'Montserrat', sans-serif;
    color: white;
    margin-bottom: 10px;
}
.footer_1 p{
    color: white;
    margin: 5px;
    font-family: serif;
    /* spacing: 1rem; */
}
.footer_2{
    display: flex;
    flex-direction: column;
    width: 40%;
    height: 250px;
    margin-top: 5px;
}
.foot{
    width: 100%;
    height: 125px;
    padding: 5px;
}
.foot p{
    color: white;
    font-family: serif;
    margin: 5px;
}
.feet{
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 125px;
}
.myfooter{
    width:100px;
    margin-top: 10px;

}
.feet h2{
    padding: 5px;
    color: white;
    font-family: 'Montserrat', sans-serif;

}
.feet p{
    padding: 5px;
    color: white;
}
@media(max-width:775px){
    .footer{
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        height: 650px;
    }
}
@media(max-width:716px){
    /* .footer{
        height:500px;
    } */
    .footer_1{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        width: 70%;}

        .footer_2{
            display: flex;
            flex-direction: row;
            width: 70%;
            justify-content: space-around;
            /* height: 250px;
            margin-top: 5px; */
        }  
        .foot{
            display: flex;
            flex-direction: column;
        
            align-items: center;
        }
        .feet{
            display: flex;
            flex-direction: row;
            justify-content: center;
            width: 100%;
            height: 125px;
        }
    }
    @media (max-width:400px){
        .footer_2{
            display: none;
        }
    }


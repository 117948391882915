
.hero{
    width: 100%;
    /* height: 500px; */
    background-color: rgb(1, 1, 26);
}

.mysvg{
    margin: 3rem;
}
.header{
    display: flex;
    align-items: center;
    margin: auto;
    left:0;
    width: 100%;
    background-color: rgb(1, 1, 26);
    position: fixed;
    top: 0;
}

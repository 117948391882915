.nav {
    margin-left: auto;
    padding:5px;


    
}


.nav ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: flex-end;
    gap: 3rem;
    align-items: center;
    margin-right: 2rem;
}
.nav li a{
    font-family: 'Montserrat', sans-serif;
    color: white;
    text-decoration: none;
    gap: 3rem;
    /* padding-top: 5px; */
    font-size: 12px;
    /* margin-left:auto; */
    
}

.button{
    background-color: blue;
    height: 30px;
    width: 70px;
    color: white;
    outline: none;
    cursor: pointer;
    border: none;
    margin: 3rem;
    /* font-size: 20px; */
    font-weight: 200;
}
@media(max-width:600px){
    .nav ul{
    display: none;    
    }
    
}
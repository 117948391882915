.video{
    
    width:80%;
    display:flex;
    flex-direction:row;
    margin:auto;
    margin-top: 80px;
    background-color: whitesmoke;
    gap: 3rem;
    align-items:center
}

.item{
    align-items: center;
    margin: 30px;
    
}
.item h2{
    font-family: 'Montserrat', sans-serif;
}
.button{
    background-color: blue;
    height: 30px;
    width: 170px;
    color: white;
    outline: none;
    cursor: pointer;
    border: none;
    margin: 3rem;
    font-weight: 200;
}
.myvid{
    margin: 30px;
    width:500px;
}
@media (max-width:1017px){
    .video{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .item{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .item h2{
        padding: 8px;
    }
    .item p{
        text-align: center;
    }
}